exports.components = {
  "component---src-components-templates-page-tsx": () => import("./../../../src/components/templates/Page.tsx" /* webpackChunkName: "component---src-components-templates-page-tsx" */),
  "component---src-components-templates-resources-category-tsx": () => import("./../../../src/components/templates/ResourcesCategory.tsx" /* webpackChunkName: "component---src-components-templates-resources-category-tsx" */),
  "component---src-components-templates-scientific-update-category-tsx": () => import("./../../../src/components/templates/ScientificUpdateCategory.tsx" /* webpackChunkName: "component---src-components-templates-scientific-update-category-tsx" */),
  "component---src-components-templates-scientific-update-tsx": () => import("./../../../src/components/templates/ScientificUpdate.tsx" /* webpackChunkName: "component---src-components-templates-scientific-update-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-befr-search-tsx": () => import("./../../../src/pages/befr/search.tsx" /* webpackChunkName: "component---src-pages-befr-search-tsx" */),
  "component---src-pages-benl-search-tsx": () => import("./../../../src/pages/benl/search.tsx" /* webpackChunkName: "component---src-pages-benl-search-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-nl-search-tsx": () => import("./../../../src/pages/nl/search.tsx" /* webpackChunkName: "component---src-pages-nl-search-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-ui-kit-index-tsx": () => import("./../../../src/pages/ui-kit/index.tsx" /* webpackChunkName: "component---src-pages-ui-kit-index-tsx" */),
  "component---src-pages-uk-search-tsx": () => import("./../../../src/pages/uk/search.tsx" /* webpackChunkName: "component---src-pages-uk-search-tsx" */)
}

